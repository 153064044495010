@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.styles_centerCard__1ydpn {
    padding: 50px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}
.styles_centerCard__1ydpn img {
    width: 800px;
    height: auto;
}

@media screen and (max-width: 1000px) {
    .styles_centerCard__1ydpn img {
        width: 100%;
        height: auto;
    }
}

.styles_headBanner__376vk {
    text-align: center;
    height: 500px;
    padding: 100px 0px;
}
.styles_headBanner__376vk p {
    font-size: 2.2em;
}

.styles_headBanner__376vk button {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 1em;
    font-weight: 700;

    height: 40px;
    width: 150px;
    margin: 25px 10px;

    border-width: 0px;
    border-radius: 8px;
    background-color: #c4c4c4;
}
.styles_headBanner__376vk button:hover {
    background-color: #adadad;
}
.styles_headBanner__376vk button:focus {
    outline: none;
}
.styles_headBanner__376vk button:active {
    color: white;
    background-color: #838383;
}

.styles_whiteBackground__2uFzP {
    background-color: white;
}

.styles_featureContainer__2-kdY {
    padding: 100px 50px;
    text-align: center;
}

.styles_featureContainer__2-kdY h1 {
    font-size: 4em;
    letter-spacing: -3px;
}
.styles_featureContainer__2-kdY p {
    font-weight: 400;
    font-size: 1.5em;
}

.styles_feature__1O0mu {
    text-align: center;
    padding: 20px 40px;
}

.styles_featureRow__tWjvK {
    display: flex;
    flex-direction: row;
}

.styles_featureCol__3k0ww {
    display: flex;
    flex-direction: column;
}

.styles_contactDetails__OxcBk {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    padding: 40px 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.styles_developer__kgCZF,
.styles_developerDetails__2EH4E {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.styles_developerDetails__2EH4E {
    text-align: right;
}
.styles_developer__kgCZF a:hover {
    color: blue;
}

.styles_navbarContainer__3VWkx {
    padding: 30px 15%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
}
.styles_navbarItems__3tnHw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_navbarLogo__UZhND img {
    width: 70px;
    height: 70px;
    transition: ease-in-out 0.5s;
}
.styles_navbarLogo__UZhND img:hover {
    transition: ease-in-out 0.5s;
    transform: rotate(130deg);
    width: 80px;
    height: 80px;
}
.styles_navbarLinksContainer__z7bQ0 {
    display: grid;
    place-items: center;
    height: 100%;
}
.styles_navbarLinks__N7c5G {
    font-family: "Poppins", sans-serif;
    font-size: large;
    font-weight: 600;
    letter-spacing: -0.5px;
    display: flex;
    justify-content: flex-end;
}
.styles_navbarLink__2_fBH {
    margin: 0px 8px;
    transition: ease-in-out 0.2s;
}
.styles_navbarLink__2_fBH:hover {
    transition: ease-in-out 0.2s;
    transform: translateY(5px);
}
.styles_currentLink__3xZ7H {
    font-weight: 700;
    color: #c5b100;
}

.styles_footerContainer__32Ste {
    background-color: black;
    height: 60px;
    display: grid;
    place-items: center;
}

.styles_footerTextContainer__1A7iF {
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: lighter;
}

.styles_cardContainer__3L7DQ {
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.styles_cardForm__3oYFU {
    padding: 30px 80px;
    height: auto;
    width: auto;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 30px #c4c4c4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "Poppins", sans-serif;
}
.styles_cardForm__3oYFU img {
    width: 250px;
    height: auto;
    margin: 20px;
}
.styles_cardForm__3oYFU h3 {
    margin-bottom: 20px;
}
.styles_cardForm__3oYFU a {
    margin: 5px 0px;
    color: #838383;
    font-size: small;
}
.styles_cardForm__3oYFU a:hover {
    color: black;
}
.styles_input__Ur_mF {
    font-family: "Poppins", sans-serif;

    height: 40px;
    width: 250px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;

    background-color: #c4c4c4;
}
.styles_input__Ur_mF::-webkit-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__Ur_mF:-ms-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__Ur_mF::placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__Ur_mF:focus {
    outline: none;
    border: 2px #838383 solid;
}
.styles_button__1DsUM {
    cursor: pointer;
    font-weight: 700;

    height: 40px;
    padding: 0px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px 0px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_button__1DsUM:hover {
    background-color: #ddc700;
}
.styles_button__1DsUM:focus {
    outline: none;
}
.styles_button__1DsUM:active {
    color: white;
    background-color: #838383;
}

.styles_cardContainer__35601 {
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.styles_cardForm___IEXu {
    padding: 30px 80px;
    height: auto;
    width: auto;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 30px #c4c4c4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "Poppins", sans-serif;
}
.styles_cardForm___IEXu img {
    width: 250px;
    height: auto;
    margin: 20px;
}
.styles_cardForm___IEXu h3 {
    margin-bottom: 20px;
}
.styles_cardForm___IEXu a {
    margin: 5px 0px;
    color: #838383;
    font-size: small;
}
.styles_cardForm___IEXu a:hover {
    color: black;
}
.styles_input__JZoBM {
    font-family: "Poppins", sans-serif;

    height: 40px;
    width: 250px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;

    background-color: #c4c4c4;
}
.styles_input__JZoBM::-webkit-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__JZoBM:-ms-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__JZoBM::placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__JZoBM:focus {
    outline: none;
    border: 2px #838383 solid;
}
.styles_button__36wuG {
    cursor: pointer;
    font-weight: 700;

    height: 40px;
    padding: 0px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px 0px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_button__36wuG:hover {
    background-color: #ddc700;
}
.styles_button__36wuG:focus {
    outline: none;
}
.styles_button__36wuG:active {
    color: white;
    background-color: #838383;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #eeeeee;
}

/* New Design */

.styles_cardContainer__ilpzh {
    margin: 20px 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.styles_addNewContainer__1B6fq {
    padding: 50px;
    display: flex;
    justify-content: center;
}

.styles_footerContainer__32Scl {
    margin-top: 50px;
}

/* Design elements for /class/j3r894jairo48arjlqr9ajlr4 */

.styles_classDetails__30nIg {
    margin: 0% 10%;
}

.styles_table__38SCM {
    margin: 8px 0px;
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border-collapse: collapse;
}

.styles_td__mDmkT {
    padding: 0px;
    border: 1px solid #c4c4c4;
}

.styles_tableRow__1Lpj7 {
    height: 50px;
    border: 1px solid #c4c4c4;
}

.styles_tableEntry__2yUrZ {
    background-color: white;
}

.styles_deleteButton__3sZFM {
    cursor: pointer;
    color: #ff0000;
}

.styles_addNewCandidateSection__3xrfQ {
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin: 100px 0px;
}

.styles_addNewCandidateSection__3xrfQ h2 {
    letter-spacing: -1px;
}

.styles_addNewCandidateCard__38wDf {
    background-color: #c4c4c4;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 4px;
    margin: 20px 0px 50px 0px;
}

.styles_input__3gtPo {
    font-family: "Poppins", sans-serif;

    margin: 0px 4px;
    height: 40px;
    width: 200px;
    text-align: center;
    border-radius: 6px;
    border-width: 0px;
}
.styles_input__3gtPo::-webkit-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__3gtPo:-ms-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__3gtPo::placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__3gtPo:focus {
    outline: none;
}

.styles_addButton__1bsGr {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    height: 40px;
    width: 100px;
    margin: 4px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_addButton__1bsGr:hover {
    background-color: #ddc700;
}
.styles_addButton__1bsGr:focus {
    outline: none;
}
.styles_addButton__1bsGr:active {
    color: white;
    background-color: #838383;
}

/* --------------------------------------------- */
/* .listItemContainer {
    background-color: white;

    padding: 0px 20px;
    height: 50px;
    border: solid darkgray 1px;
    border-top-width: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.listTitleContainer {
    background-color: #eeeeee;
}

.listItem {
    display: flex;
    justify-content: space-evenly;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

*/

.styles_pageHeader__3_0Ot {
    text-align: center;
    margin-top: 50px;
}

.styles_pageHeader__3_0Ot h1 {
    font-family: "Poppins", sans-serif;
    font-size: 80px;
    letter-spacing: -4px;
}

.styles_searchBarContainer__hIuyN {
    display: flex;
    justify-content: center;
}
.styles_searchBar__1ko2R {
    font-family: "Poppins", sans-serif;

    margin: 20px;
    height: 40px;
    width: 400px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;

    background-color: #c4c4c4;
}
.styles_searchBar__1ko2R::-webkit-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_searchBar__1ko2R:-ms-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_searchBar__1ko2R::placeholder {
    text-align: center;
    color: #838383;
}
.styles_searchBar__1ko2R:focus {
    outline: none;
}

a {
    text-decoration: none;
    color: inherit;
}

.styles_card__2hQ6- {
    margin: 8px;
    width: 300px;
    text-align: center;
    background-color: white;
    border-radius: 10px;

    transition: ease-in-out 0.1s;
}

.styles_card__2hQ6-:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.1s;
}

.styles_cardHeader__euDb1 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1.1;

    height: 100px;
    background-color: #ffe600;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
}

.styles_cardHeader__euDb1:active {
    background-color: #838383;
}

.styles_deleteCard__3R7lY {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
}
.styles_deleteCross__nZrtC {
    transition: ease-in-out 0.2s;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.styles_deleteCross__nZrtC:hover {
    transform: scale(1.4);
    color: rgb(255, 0, 0);
    stroke-width: 3px;
    transition: ease-in-out 0.2s;
}
.styles_deleteCross__nZrtC:active {
    transform: scale(1.1);
    color: rgb(0, 0, 0);
    stroke-width: 3px;
    transition: ease-in-out 0.2s;
}
.styles_cardFooter__ugInU {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    letter-spacing: -0.5px;

    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.styles_addNewCard__pbdjS {
    font-family: "Poppins", sans-serif;
    letter-spacing: -1.5px;
    text-align: center;
}

.styles_card__1aUqo {
    background-color: white;
    border-radius: 10px;
}

.styles_inputArea__2xCC3 {
    background-color: #c4c4c4;
    border-radius: 10px;
}

.styles_input__1za0A {
    font-family: "Poppins", sans-serif;

    margin: 15px;
    height: 40px;
    width: 250px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;
}
.styles_input__1za0A:focus {
    border: 2px #838383 solid;
}
.styles_input__1za0A::-webkit-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__1za0A:-ms-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__1za0A::placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__1za0A:focus {
    outline: none;
}

.styles_addButton__1AXDm {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    height: 40px;
    width: 100px;
    margin: 10px;

    border-width: 0px;
    border-radius: 8px;
    background-color: #c4c4c4;
}
.styles_addButton__1AXDm:hover {
    background-color: #adadad;
}
.styles_addButton__1AXDm:focus {
    outline: none;
}
.styles_addButton__1AXDm:active {
    color: white;
    background-color: #838383;
}

.styles_headerBarContainer__31UYD {
    padding: 0px 30px;

    font-family: "Poppins", sans-serif;

    display: flex;
    justify-content: space-between;
    background-color: #ffe600;
    height: 70px;

    border-radius: 10px;
}

.styles_backButtonContainer__2PEIC {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.styles_backButton__SYov1 {
    height: 42px;
    width: 42px;
    background-color: black;
    color: white;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 10px;

    transition: ease-in-out 0.1s;
}

.styles_backButton__SYov1:hover {
    transform: scale(1.1);
    transition: ease-in-out 0.1s;
}

.styles_backButton__SYov1:active {
    transform: scale(1);
    background-color: #838383;
}

.styles_headerContainer__3DOyh {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: bold;
    letter-spacing: -1px;
}

.styles_sideHeaderContainer__IvU3F {
    display: flex;
    flex-direction: column;
    justify-content: center;

    letter-spacing: -0.5px;
}

.styles_footerContainer__2KIMJ {
    margin-top: 50px;
}
.styles_listBlock__1Ys6w {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.styles_addNewExamContainer__1ZPMn {
    font-family: "Poppins", sans-serif;
    margin: 70px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.styles_addNewExamContainer__1ZPMn h1 {
    letter-spacing: -1px;
}

.styles_newExamInputContainer__3dSey {
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    background-color: #c4c4c4;
}

.styles_inputRow__2O0vD {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

input,
select {
    margin: 5px;
    height: 40px;
    /* width: 200px; */
    padding: 5px 10px;
    border-radius: 5px;
    border-width: 0px;
}
.styles_formText__3Yl1- {
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.styles_addButton__MhHdy {
    cursor: pointer;
    font-weight: 700;

    height: 40px;
    padding: 0px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_addButton__MhHdy:hover {
    background-color: #ddc700;
}
.styles_addButton__MhHdy:focus {
    outline: none;
}
.styles_addButton__MhHdy:active {
    color: white;
    background-color: #838383;
}

/* for exam candidates/results table */
.styles_examDetails__2gEbr {
    padding: 0% 10%;
    width: 100%;
}

.styles_cardLinkRow__2Gq10 {
    margin: 5px 0px;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    background-color: #838383;
    border-radius: 5px;
    color: white;
}

.styles_cardLink__1EnFk {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.styles_cardLinkCopy__2Qv3N {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;

    height: 40px;

    padding: 0px 10px;
    background-color: black;
    color: white;
    border-radius: 5px;
}

.styles_cardLinkCopy__2Qv3N p {
    font-weight: 600;
}
.styles_cardLinkCopy__2Qv3N:hover {
    background-color: #838383;
}
.styles_cardLinkCopy__2Qv3N:active {
    background-color: #7e7100;
}

.styles_examDetailsCard__MlJix {
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.styles_examDetailsTextContainer__oRf4t {
    background-color: #c4c4c4;
    padding: 0px 20px;
    margin: 20px 0px 50px 0px;
    border-radius: 5px;
}

.styles_detailsCardTable__15awW {
    padding: 20px 0px;
}

.styles_detailsCardTableRow__38Uz6 {
    height: 30px;
}

.styles_detailsCardTableRow__38Uz6 td {
    padding: 0px 10px;
}

.styles_tableHeader__3THwo {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
}

.styles_greenTd__3-yzE {
    background-color: #d3ffe0;
}
.styles_redTd__24B-P {
    background-color: #ff8888;
}

.styles_table__riAgi {
    margin: 8px 0px;
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border-collapse: collapse;
}

.styles_td__2a15b {
    padding: 0px;
    border: 1px solid #c4c4c4;
}

.styles_tableRow__38tCj {
    height: 50px;
    border: 1px solid #c4c4c4;
}

.styles_tableEntry__3DECV {
    background-color: white;
}

a {
    text-decoration: none;
    color: inherit;
}
.styles_card__cujJY {
    margin: 8px 0px;
    font-family: "Poppins", sans-serif;
    width: 70%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}
@media screen and (max-width: 1250px) {
    .styles_card__cujJY {
        width: 90%;
    }
}

.styles_cardColorTab__1Thp2 {
    width: 30px;
    background-color: #ffe500;
    border-radius: 10px 5px 5px 10px;
}
.styles_cardContent__36dda {
    width: 100%;
    padding: 10px 20px 10px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.styles_cardHeadRow__2pPOV,
.styles_cardLinkRow__1-Xzh,
.styles_cardButtons__1wMNT {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.styles_cardHeadRow__2pPOV {
    margin-bottom: 5px;
}

.styles_cardHeadItem__12xVx {
    margin-right: 15px;
}

.styles_cardHeadTitle__J5Rvy {
    letter-spacing: -0.8px;
}

.styles_cardHeadDate__24pqW {
    letter-spacing: -0.3px;
    color: #888888;
}

.styles_cardLinkRow__1-Xzh {
    border-radius: 5px;
    background-color: #eeeeee;
}

.styles_cardLink__MrjGs {
    padding: 5px 15px;
}

.styles_cardLinkURLclass__2s0qn {
    overflow-wrap: normal;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    width: 450px;
}

.styles_cardLinkCopy__1qvdX {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;

    height: 35px;

    padding: 0px 10px;
    background-color: #ffe500;
    border-radius: 5px;
}

.styles_cardLinkCopy__1qvdX p {
    font-weight: 600;
}
.styles_cardLinkCopy__1qvdX:hover {
    color: white;
    background-color: #838383;
}
.styles_cardLinkCopy__1qvdX:active {
    color: white;
    background-color: #7e7100;
}

.styles_button__2KTHA {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
    margin: 0px 10px;
    width: 50px;
    height: 50px;
    background-color: #eeeeee;
}
.styles_button__2KTHA:hover {
    transform: scale(1.1);
    background-color: #ffe500;
}
.styles_delButton__3NFzx:hover {
    background-color: #ff8888;
}
.styles_button__2KTHA:focus {
    outline: none;
}
.styles_button__2KTHA:active {
    color: white;
    background-color: #838383;
}

/* New Design */

.styles_cardContainer__lhOGv {
    margin: 20px 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.styles_addNewContainer__25Rlm {
    padding: 50px;
    display: flex;
    justify-content: center;
}

.styles_footerContainer__3_BfO {
    margin-top: 50px;
}

/* QuestionBank details */

.styles_questionBankDetails__38TVS {
    margin: 0% 10%;
}

.styles_customColumn__1M9Hw {
    display: flex;
    flex-direction: column;
}

.styles_listBlock__bxYRU {
    margin: 8px 0px;
}

.styles_table__2dt_4 {
    margin: 0px;
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border-collapse: collapse;
}

.styles_td__Httok {
    padding: 0px;
    border: 1px solid #c4c4c4;
}

.styles_questionTD__2POrA {
    width: 60%;
    padding: 10px 10px;
    word-wrap: normal;
}

.styles_optionsTD__1QeVA {
    padding: 10px 5px;
}

.styles_correctOptionTD__1-9el {
    color: #009a2e;
}

.styles_tableRow__1e4Np {
    height: 50px;
    border: 1px solid #c4c4c4;
}

.styles_tableEntry__UTwGs {
    background-color: white;
}

.styles_deleteButton__14Xqw {
    cursor: pointer;
    color: #ff0000;
}

.styles_addNewQuestionSection__1fEND {
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin: 100px 0px;
}

.styles_addNewQuestionSection__1fEND h2 {
    letter-spacing: -1px;
}

.styles_addNewQuestionCard__2nrQl {
    display: flex;
    flex-direction: column;

    background-color: #c4c4c4;
    border-radius: 10px;
    width: 100%;
    padding: 4px 4px;
    margin: 20px 0px 50px 0px;
}

.styles_addNewQuestionCardRow__EvIfB {
    display: flex;
    flex-direction: row;
}

.styles_checkMark__e5aBS {
    cursor: pointer;
    color: #d3ffe0;
}

.styles_checkMark__e5aBS:hover {
    color: #b8e4c4;
}

.styles_checkMark__e5aBS:active {
    color: #838383;
}

.styles_checkMarkChecked__1vctp {
    color: #009a2e;
}
.styles_checkMarkChecked__1vctp:hover {
    color: #007523;
}

.styles_addNewOptionCardButtonRow__jirSD {
    display: flex;
    flex-direction: row-reverse;
}

.styles_input__27ZlP {
    font-family: "Poppins", sans-serif;

    margin: 4px 4px;
    height: 40px;
    width: 200px;

    text-align: left;
    padding-left: 10px;

    border-radius: 6px;
    border-width: 0px;
}
.styles_input__27ZlP::-webkit-input-placeholder {
    color: #838383;
}
.styles_input__27ZlP:-ms-input-placeholder {
    color: #838383;
}
.styles_input__27ZlP::placeholder {
    color: #838383;
}
.styles_input__27ZlP:focus {
    outline: none;
    border: 2px #838383 solid;
}

.styles_addNewText__GrJeY {
    display: grid;
    place-items: center;
    margin: 0px 10px;
    width: 20px;
}

.styles_questioninput__2m5gh {
    width: 100%;
}

.styles_optionsinput__OkHHL {
    width: 100%;
}

.styles_addButton__2sQMg {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    height: 40px;
    padding: 0px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_addButton__2sQMg:hover {
    background-color: #ddc700;
}
.styles_addButton__2sQMg:focus {
    outline: none;
}
.styles_addButton__2sQMg:active {
    color: white;
    background-color: #838383;
}

.styles_removeOption__3W0fy {
    cursor: pointer;
    background-color: #ff8888;
}
.styles_removeOption__3W0fy:hover {
    background-color: #e07979;
}
.styles_addOption__2LEAE {
    cursor: pointer;
    background-color: #d3ffe0;
}
.styles_addOption__2LEAE:hover {
    background-color: #b8e4c4;
}

/* Candidate Login */
.styles_cardContainer__31hm- {
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.styles_cardForm__2mSpg {
    padding: 30px 80px;
    height: auto;
    width: auto;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 30px #c4c4c4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "Poppins", sans-serif;
}
.styles_cardForm__2mSpg img {
    width: 250px;
    height: auto;
    margin: 20px;
}
.styles_cardForm__2mSpg h3 {
    margin-bottom: 20px;
}
.styles_cardForm__2mSpg a {
    margin: 5px 0px;
    color: #838383;
    font-size: small;
}
.styles_cardForm__2mSpg a:hover {
    color: black;
}
.styles_input__1Bo7d {
    font-family: "Poppins", sans-serif;

    height: 40px;
    width: 250px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;

    background-color: #c4c4c4;
}
.styles_input__1Bo7d::-webkit-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__1Bo7d:-ms-input-placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__1Bo7d::placeholder {
    text-align: center;
    color: #838383;
}
.styles_input__1Bo7d:focus {
    outline: none;
    border: 2px #838383 solid;
}
.styles_button__2hZRm {
    cursor: pointer;
    font-weight: 700;

    height: 40px;
    padding: 0px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px 0px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_button__2hZRm:hover {
    background-color: #ddc700;
}
.styles_button__2hZRm:focus {
    outline: none;
}
.styles_button__2hZRm:active {
    color: white;
    background-color: #838383;
}
/* end of candidate login */

/* Exam Interface */
.styles_examPageContainer__aRuak {
    font-family: "Poppins", sans-serif;
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
}
.styles_topBar__2yzge {
    width: 100%;
    height: 10%;
    background-color: #c4c4c4;

    padding-right: 25px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.styles_logoContainer__3X_wF {
    height: 70px;
    width: 70px;
    overflow: hidden;
}
.styles_logoContainer__3X_wF img {
    height: 100%;
    width: 100%;
}

.styles_infoStack__wqjES {
    margin: 0px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    line-height: 1.1;
}
.styles_infoName__2WKmd {
    font-size: 0.9em;
    font-weight: 700;
    color: #838383;
}

.styles_colorGrey__2fi3f {
    color: #838383;
}

.styles_darkgreen__2aS-K {
    color: #009a2e;
}
.styles_darkviolet__1TSAJ {
    color: #3a4eff;
}
.styles_darkred__1K1T8 {
    color: #d72323;
}

.styles_buttonSubmit__1GGtn {
    font-weight: 700;
    font-size: 1em;
}

.styles_questionsAttemptDetails__2972Y {
    display: flex;
    flex-direction: row;
}

.styles_mainContainer__3aMJa {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    height: 90%;
}
.styles_questionIndexList__226NB {
    width: 70px;
    max-height: 100%;
    overflow-y: scroll;
    background-color: #c4c4c4;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}
.styles_questionIndexList__226NB::-webkit-scrollbar {
    /* WebKit */
    width: 0px;
}
.styles_questionIndex__z1kfu {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    font-size: 1.1em;
    font-weight: 800;

    margin: 1px;
    width: 68px;
    height: 68px;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}
.styles_questionIndex__z1kfu:hover {
    border: 2px black solid;
}
.styles_questionIndex__z1kfu:active {
    background-color: #7b721c;
}

.styles_lightViolet__3o3sq {
    background-color: #99a3ff;
}

.styles_lightGreen__1tuei {
    background-color: #d3ffe0;
}

.styles_lightRed__3JIRL {
    background-color: #ff8888;
}
.styles_yellow__pj5Qs {
    background-color: #ffe600;
}

/* Question Container */

.styles_questionContainer__1LrpL {
    height: 100%;
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: scroll;
}

.styles_quesitonResponseCardContainer__1Bjbd {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.styles_quesitonResponseCard__2fBhh {
    height: 100%;
    width: 100%;
    background-color: #dfdede;
    padding: 50px;
    border-radius: 10px;
}

.styles_quesitonResponseCard_question__2XbsH {
    margin: 30px 0px 10px 20px;
}

.styles_quesitonResponseCard_options__28hUF {
    margin: 20px;
}

.styles_quesitonResponseCard_options__28hUF p {
    font-weight: 700;
    margin: 0px 10px;
}

.styles_quesitonResponseCard_option__w3zOC {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.styles_quesitonResponseCard_option__w3zOC i {
    margin: 10px 10px;
}
.styles_checkMark__32ale {
    cursor: pointer;
    color: #d3ffe0;
}

.styles_checkMark__32ale:hover {
    color: #b8e4c4;
}

.styles_checkMark__32ale:active {
    color: #838383;
}

.styles_checkMarkChecked__1bctw {
    color: #009a2e;
}
.styles_checkMarkChecked__1bctw:hover {
    color: #007523;
}

.styles_quesitonResponseCard_buttons__2uMO_ {
    display: flex;
    flex-direction: row-reverse;
}

.styles_quesitonResponseCard_buttons__2uMO_ button {
    margin: 10px;
}

.styles_confirmButton__1NQzt {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 1em;

    height: 50px;
    padding: 0px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 4px;

    border-width: 0px;
    border-radius: 6px;
    background-color: #ffe600;
}
.styles_confirmButton__1NQzt:hover {
    background-color: #ddc700;
}
.styles_confirmButton__1NQzt:focus {
    outline: none;
}
.styles_confirmButton__1NQzt:active {
    color: white;
    background-color: #838383;
}

/* end of exam Interface */

/* Result page */
.styles_resultPage__xqhUf {
    font-family: "Poppins", sans-serif;
    height: 100vh;
}
.styles_reportContainer__lsIKf {
    margin: 80px;
}
.styles_reportContainer__lsIKf img {
    height: auto;
    width: 300px;
}
.styles_reportDate__1Xe5U {
    margin: 0px 20px;
}
.styles_displayAsRow__w0ZgC {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.styles_displayAsRow__w0ZgC * {
    margin: 0px 5px;
}
.styles_candidateInfo__36PrA {
    margin: 40px 20px;
}
.styles_infograph__2vzqC td {
    padding: 10px;
}
.styles_infoBarContainer__gakWp {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.styles_infoBar__2pioQ {
    margin: 0px 10px;
    height: 20px;
    background-color: black;
}
.styles_examinerInfo__2uZ9n {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

